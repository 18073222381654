<template>
  <div>
    <!-- resolveFormType : {{ resolveFormType }} -->
    <car-master-maintainance-popup-branch
      :temp-search-branch="tempSearchBranch"
      :is-popup-branch-active.sync="isPopupBranchActive"
      @select-item="fncSelectBranch"
    />
    <car-master-maintainance-popup-car-brand
      :temp-search-car-brand="tempSearchCarBrand"
      :is-popup-car-brand-active.sync="isPopupCarBrandActive"
      @select-item="fncSelectCarBrand"
    />
    <car-master-maintainance-popup-car-model
      :temp-search-car-model="tempSearchCarModel"
      :is-popup-car-model-active.sync="isPopupCarModelActive"
      @select-item="fncSelectCarModel"
    />
    <car-master-maintainance-popup-car-color
      :temp-search-car-color="tempSearchCarColor"
      :is-popup-car-color-active.sync="isPopupCarColorActive"
      @select-item="fncSelectCarColor"
    />
    <car-master-maintainance-popup-car-energy
      :temp-search-car-energy="tempSearchCarEnergy"
      :is-popup-car-energy-active.sync="isPopupCarEnergyActive"
      @select-item="fncSelectCarEnergy"
    />
    <car-master-maintainance-popup-car-group
      :temp-search-car-group="tempSearchCarGroup"
      :is-popup-car-group-active.sync="isPopupCarGroupActive"
      @select-item="fncSelectCarGroup"
    />
    <car-master-maintainance-popup-car-type
      :temp-search-car-type="tempSearchCarType"
      :is-popup-car-type-active.sync="isPopupCarTypeActive"
      @select-item="fncSelectCarType"
    />
    <car-master-maintainance-popup-cooperate
      :temp-search-cooperate="tempSearchCooperate"
      :is-popup-cooperate-active.sync="isPopupCooperateActive"
      @select-item="fncSelectCooperate"
    />
    <car-master-maintainance-popup-car-dealer
      :temp-search-car-dealer="tempSearchCarDealer"
      :is-popup-car-dealer-active.sync="isPopupCarDealerActive"
      @select-item="fncSelectCarDealer"
    />
    <car-master-maintainance-popup-car-location
      :temp-search-car-location="tempSearchCarLocation"
      :is-popup-car-location-active.sync="isPopupCarLocationActive"
      @select-item="fncSelectCarLocation"
    />
    <car-master-maintainance-popup-province
      :temp-search-province="tempSearchProvince"
      :is-popup-province-active.sync="isPopupProvinceActive"
      @select-item="fncSelectProvince"
    />
    <car-master-maintainance-popup-car-register-type
      :temp-search-car-register-type="tempSearchCarRegisterType"
      :is-popup-car-register-type-active.sync="isPopupCarRegisterTypeActive"
      @select-item="fncSelectCarRegisterType"
    />
    <car-master-maintainance-popup-g-p-s-code
      :temp-search-g-p-s-code="tempSearchGPSCode"
      :is-popup-g-p-s-code-active.sync="isPopupGPSCodeActive"
      @select-item="fncSelectGPSCode"
    />
    <b-modal
      ref="refModalDatePickerRegisterDate"
      centered
      title="วันที่จดทะเบียน"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="registerDate"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerRegisterDate.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerGPSDateStart"
      centered
      title="วันที่ติดตั้ง GPS"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="GPSDateStart"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerGPSDateStart.hide()"
      />
    </b-modal>
    <b-card
      :title="`ข้อมูลหลักรถยนต์ ฟอร์ม ${resolveFormType == 'add' ? 'เพิ่มข้อมูล' : resolveFormType == 'edit' ? 'แก้ไขข้อมูล' : 'ลบข้อมูล'}`"
    >
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="branchCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-input-group v-show="!branchCode">
              <b-form-input
                id="branchCode"
                v-model="inputSearchBranch"
                placeholder="ค้นหาพอร์ต"
                trim
                @keydown.enter.native="
                  fncSearchBranch(inputSearchBranch)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupBranch()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="branchCode">
              <b-form-input
                id="branchCode"
                :value="branchName"
                trim
                disabled
              />
              <b-input-group-append v-if="!isDefaultBranchFromLogin && !branchFromEdit && !chassisNumFromEdit">
                <b-button
                  variant="primary"
                  @click="fncCancelBranch()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเลขตัวถัง"
            label-for="chassisNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเลขตัวถัง
              </div>
            </template>
            <b-form-input
              id="chassisNum"
              v-model="InputchassisNum"
              placeholder="หมายเลขตัวถัง"
              trim
              autocomplete="off"
              :disabled="branchFromEdit && chassisNumFromEdit ? true : false"
            />
            <span
              v-if="isDuplicateData"
              class="text-danger"
            >
              ขออภัย หมายเลขตัวถังนี้ใช้งานแล้ว
            </span>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเลขตัวถัง"
            label-for="ReferenceNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                Reference No
              </div>
            </template>
            <b-form-input
              id="ReferenceNum"
              :value="inputReferenceNum"
              placeholder="หมายเลขตัวถัง"
              trim
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเลขเครื่องยนต์"
            label-for="enginNumber"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเลขเครื่องยนต์
              </div>
            </template>
            <b-form-input
              id="enginNumber"
              v-model="inputEnginNumber"
              placeholder="หมายเลขเครื่องยนต์"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ขนาดกระบอกสูบ"
            label-for="carDisplacement"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ขนาดกระบอกสูบ
              </div>
            </template>
            <b-form-input
              id="carDisplacement"
              v-model="inputCarDisplacement"
              placeholder="ขนาดกระบอกสูบ"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carBrand"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ยี่ห้อรถ
              </div>
            </template>
            <b-input-group v-show="!carBrand">
              <b-form-input
                id="carBrand"
                v-model="inputSearchCarBrand"
                placeholder="ค้นหายี่ห้อรถ"
                trim
                @keydown.enter.native="
                  fncSearchCarBrand(inputSearchCarBrand)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarBrand()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carBrand">
              <b-form-input
                id="carBrand"
                :value="carBrand"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarBrand()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carModel"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                รุ่นรถ
              </div>
            </template>
            <b-input-group v-show="!carModel">
              <b-form-input
                id="carModel"
                v-model="inputSearchCarModel"
                placeholder="ค้นหารุ่นรถ"
                trim
                @keydown.enter.native="
                  fncSearchCarModel(inputSearchCarModel)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarModel()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carModel">
              <b-form-input
                id="carModel"
                :value="carModel"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarModel()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carColor"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สีรถ
              </div>
            </template>
            <b-input-group v-show="!carColor">
              <b-form-input
                id="carColor"
                v-model="inputSearchCarColor"
                placeholder="ค้นหาสีรถ"
                trim
                @keydown.enter.native="
                  fncSearchCarColor(inputSearchCarColor)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarColor()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carColor">
              <b-form-input
                id="carColor"
                :value="carColor"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarColor()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carEnergyCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ประเภทเชื้อเพลิง/พลังงาน
              </div>
            </template>
            <b-input-group v-show="!carEnergyCode">
              <b-form-input
                id="carEnergyCode"
                v-model="inputSearchCarEnergy"
                placeholder="ค้นหาประเภทเชื้อเพลิง/พลังงาน"
                trim
                @keydown.enter.native="
                  fncSearchCarEnergy(inputSearchCarEnergy)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarEnergy()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carEnergyCode">
              <b-form-input
                id="carEnergyCode"
                :value="carEnergyName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarEnergy()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carGroupCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                กลุ่มรถยนต์
              </div>
            </template>
            <b-input-group v-show="!carGroupCode">
              <b-form-input
                id="carGroupCode"
                v-model="inputSearchCarGroup"
                placeholder="ค้นหากลุ่มรถยนต์"
                trim
                @keydown.enter.native="
                  fncSearchCarGroup(inputSearchCarGroup)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarGroup()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carGroupCode">
              <b-form-input
                id="carGroupCode"
                :value="carGroupName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarGroup()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carTypeCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ประเภทรถยนต์
              </div>
            </template>
            <b-input-group v-show="!carTypeCode">
              <b-form-input
                id="carTypeCode"
                v-model="inputSearchCarType"
                placeholder="ค้นหาประเภทรถยนต์"
                trim
                @keydown.enter.native="
                  fncSearchCarType(inputSearchCarType)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarType()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carTypeCode">
              <b-form-input
                id="carTypeCode"
                :value="carTypeName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarType()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="cooperateCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สหกรณ์
              </div>
            </template>
            <b-input-group v-show="!cooperateCode">
              <b-form-input
                id="cooperateCode"
                v-model="inputSearchCooperate"
                placeholder="ค้นหาสหกรณ์"
                trim
                @keydown.enter.native="
                  fncSearchCooperate(inputSearchCooperate)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCooperate()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="cooperateCode">
              <b-form-input
                id="cooperateCode"
                :value="cooperateName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCooperate()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carDealerCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ตัวแทนจำหน่าย
              </div>
            </template>
            <b-input-group v-show="!carDealerCode">
              <b-form-input
                id="carDealerCode"
                v-model="inputSearchCarDealer"
                placeholder="ค้นหาตัวแทนจำหน่าย"
                trim
                @keydown.enter.native="
                  fncSearchCarDealer(inputSearchCarDealer)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarDealer()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carDealerCode">
              <b-form-input
                id="carDealerCode"
                :value="carDealerName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarDealer()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carLocation"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สถานที่เก็บรถ
              </div>
            </template>
            <b-input-group v-show="!carLocation">
              <b-form-input
                id="carLocation"
                v-model="inputSearchCarLocation"
                placeholder="ค้นหาสถานที่เก็บรถ"
                trim
                @keydown.enter.native="
                  fncSearchCarLocation(inputSearchCarLocation)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarLocation()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carLocation">
              <b-form-input
                id="carLocation"
                :value="carLocation"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarLocation()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="12">
          <hr>
          <h3>ข้อมูลทะเบียนรถ</h3>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="RegNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขทะเบียน
              </div>
            </template>
            <b-input-group>
              <b-form-input
                id="RegNum"
                v-model="inputRegNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                @keydown.enter.native="refInputRegNum2.focus()"
              />
              <b-form-input
                id="RegNum2"
                ref="refInputRegNum2"
                v-model="inputRegNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;  margin-left: 5px;"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="province"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ทะเบียนจังหวัด
              </div>
            </template>
            <b-input-group v-show="!province">
              <b-form-input
                id="province"
                v-model="inputSearchProvince"
                placeholder="ค้นหาทะเบียนจังหวัด"
                trim
                @keydown.enter.native="
                  fncSearchProvince(inputSearchProvince)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupProvince()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="province">
              <b-form-input
                id="province"
                :value="province"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelProvince()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                วันที่จดทะเบียน
              </div>
            </template>
            <b-form-input
              id="inputRegisterDate"
              :value="resolveFormatDate(registerDate)"
              trim
              readonly
              placeholder="วันที่จดทะเบียน"
              @click="fncShowDatepickerRegisterDate()"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carRegisterTypeCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ประเภทการจดทะเบียน
              </div>
            </template>
            <b-input-group v-show="!carRegisterTypeCode">
              <b-form-input
                id="carRegisterTypeCode"
                v-model="inputSearchCarRegisterType"
                placeholder="ค้นหาประเภทการจดทะเบียน"
                trim
                @keydown.enter.native="
                  fncSearchCarRegisterType(inputSearchCarRegisterType)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarRegisterType()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carRegisterTypeCode">
              <b-form-input
                id="carRegisterTypeCode"
                :value="carRegisterTypeName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarRegisterType()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="12">
          <hr>
          <h3>ข้อมูล GPS</h3>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ยี่ห้อ/รุ่น
              </div>
            </template>
            <b-input-group v-show="!GPSCode">
              <b-form-input
                id="GPSCode"
                v-model="inputSearchGPSCode"
                placeholder="ค้นหายี่ห้อ/รุ่น"
                trim
                @keydown.enter.native="
                  fncSearchGPSCode(inputSearchGPSCode)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupGPSCode()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="GPSCode">
              <b-form-input
                id="GPSCode"
                :value="GPSCode"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelGPSCode()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="GPSSerial"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเลขเครื่อง
              </div>
            </template>
            <b-form-input
              id="GPSSerial"
              v-model="inputGPSSerial"
              placeholder="หมายเลขเครื่อง"
              trim
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="GPSSimCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลข SIM GPS
              </div>
            </template>
            <b-form-input
              id="GPSSimCode"
              v-model="inputGPSSimCode"
              placeholder="เลข SIM GPS"
              trim
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="GPSDateStart"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                วันที่ติดตั้ง GPS
              </div>
            </template>
            <b-form-input
              id="GPSDateStart"
              :value="resolveFormatDate(GPSDateStart)"
              trim
              readonly
              placeholder="วันที่ติดตั้ง GPS"
              @click="fncShowDatepickerGPSDateStart()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="GPSFee"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ค่าบริการ
              </div>
            </template>
            <b-form-input
              id="GPSFee"
              v-model="inputGPSFee"
              placeholder="ค่าบริการ"
              trim
              autocomplete="off"
              type="number"
              step="0.01"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-overlay
              :show="overlaySaveButton"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                v-if="resolveFormType == 'add'"
                variant="primary"
                @click="fncSubmit('save')"
              >
                Save
              </b-button>
            </b-overlay>
            <div
              class="d-inline-block"
              :class="resolveFormType == 'add' ? 'ml-1' : null"
            >
              <b-overlay
                :show="overlaySubmitButton"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  variant="primary"
                  @click="fncSubmit()"
                >
                  <!-- const function_name = val => {
                    if(val == 'a') return 'ก'
                    if(val == 'b') return 'ข'
                    return 'ค'
                  } -->

                  {{ resolveFormType == 'add' ? 'Submit' : resolveFormType == 'edit' ? 'Edit' : 'Remove' }}
                </b-button>
              </b-overlay>
            </div>
            <b-button
              class="ml-1"
              variant="outline-secondary"
              :to="{ name: 'car-master-maintainance' }"
            >
              ยกเลิก
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BModal, BOverlay,
} from 'bootstrap-vue'
import {
  onMounted, onUnmounted, ref, computed,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import {
  VDatePicker,
} from 'vuetify/lib'
import CarMasterMaintainancePopupBranch from './car-master-maintainance-popup/CarMasterMaintainancePopupBranch.vue'
import CarMasterMaintainancePopupCarBrand from './car-master-maintainance-popup/CarMasterMaintainancePopupCarBrand.vue'
import CarMasterMaintainancePopupCarModel from './car-master-maintainance-popup/CarMasterMaintainancePopupCarModel.vue'
import CarMasterMaintainancePopupCarColor from './car-master-maintainance-popup/CarMasterMaintainancePopupCarColor.vue'
import CarMasterMaintainancePopupCarEnergy from './car-master-maintainance-popup/CarMasterMaintainancePopupCarEnergy.vue'
import CarMasterMaintainancePopupCarGroup from './car-master-maintainance-popup/CarMasterMaintainancePopupCarGroup.vue'
import CarMasterMaintainancePopupCarType from './car-master-maintainance-popup/CarMasterMaintainancePopupCarType.vue'
import CarMasterMaintainancePopupCooperate from './car-master-maintainance-popup/CarMasterMaintainancePopupCooperate.vue'
import CarMasterMaintainancePopupCarDealer from './car-master-maintainance-popup/CarMasterMaintainancePopupCarDealer.vue'
import CarMasterMaintainancePopupCarLocation from './car-master-maintainance-popup/CarMasterMaintainancePopupCarLocation.vue'
import CarMasterMaintainancePopupProvince from './car-master-maintainance-popup/CarMasterMaintainancePopupProvince.vue'
import CarMasterMaintainancePopupCarRegisterType from './car-master-maintainance-popup/CarMasterMaintainancePopupCarRegisterType.vue'
import CarMasterMaintainancePopupGPSCode from './car-master-maintainance-popup/CarMasterMaintainancePopupGPSCode.vue'

import CarMasterMaintainanceStoreModule from './CarMasterMaintainanceStoreModule'

// Notification
// eslint-disable-next-line import/order
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/order
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BModal,
    BOverlay,
    VDatePicker,
    CarMasterMaintainancePopupBranch,
    CarMasterMaintainancePopupCarBrand,
    CarMasterMaintainancePopupCarModel,
    CarMasterMaintainancePopupCarColor,
    CarMasterMaintainancePopupCarEnergy,
    CarMasterMaintainancePopupCarGroup,
    CarMasterMaintainancePopupCarType,
    CarMasterMaintainancePopupCooperate,
    CarMasterMaintainancePopupCarDealer,
    CarMasterMaintainancePopupCarLocation,
    CarMasterMaintainancePopupProvince,
    CarMasterMaintainancePopupCarRegisterType,
    CarMasterMaintainancePopupGPSCode,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appCarMasterMaintainance'
    const toast = useToast()

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, CarMasterMaintainanceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // ประกาศตัวแปร Ref
    const refModalDatePickerRegisterDate = ref(null)
    const refModalDatePickerGPSDateStart = ref(null)

    // ประกาศตัวแปร Textbox
    const inputSearchBranch = ref(null)
    const InputchassisNum = ref(null)
    const inputReferenceNum = ref(null)
    const inputEnginNumber = ref(null)
    const inputCarDisplacement = ref(null)
    const inputSearchCarBrand = ref(null)
    const inputSearchCarModel = ref(null)
    const inputSearchCarColor = ref(null)
    const inputSearchCarEnergy = ref(null)
    const inputSearchCarGroup = ref(null)
    const inputSearchCarType = ref(null)
    const inputSearchCooperate = ref(null)
    const inputSearchCarDealer = ref(null)
    const inputSearchCarLocation = ref(null)
    const inputRegNum1 = ref(null)
    const inputRegNum2 = ref(null)
    const inputSearchProvince = ref(null)
    const inputSearchCarRegisterType = ref(null)
    const inputSearchGPSCode = ref(null)
    const inputGPSSerial = ref(null)
    const inputGPSSimCode = ref(null)
    const inputGPSFee = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const username = ref(null)
    const cpncod = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const carBrand = ref(null)
    const carModel = ref(null)
    const carColor = ref(null)
    const carEnergyCode = ref(null)
    const carEnergyName = ref(null)
    const carGroupCode = ref(null)
    const carGroupName = ref(null)
    const carTypeCode = ref(null)
    const carTypeName = ref(null)
    const cooperateCode = ref(null)
    const cooperateName = ref(null)
    const carDealerCode = ref(null)
    const carDealerName = ref(null)
    const carLocation = ref(null)
    const province = ref(null)
    const carRegisterTypeCode = ref(null)
    const carRegisterTypeName = ref(null)
    const tempSearchBranch = ref(null)
    const tempSearchCooperate = ref(null)
    const tempSearchCarDealer = ref(null)
    const tempSearchCarBrand = ref(null)
    const tempSearchCarModel = ref(null)
    const tempSearchCarColor = ref(null)
    const tempSearchCarEnergy = ref(null)
    const tempSearchCarGroup = ref(null)
    const tempSearchCarType = ref(null)
    const tempSearchCarLocation = ref(null)
    const tempSearchProvince = ref(null)
    const tempSearchCarRegisterType = ref(null)
    const tempSearchGPSCode = ref(null)
    const isDefaultBranchFromLogin = ref(false)
    const registerDate = ref(null)
    const GPSDateStart = ref(null)
    const GPSCode = ref(null)
    const isDuplicateData = ref(false)
    const chassisNumFromEdit = ref(null)
    const getBranchCode = router.currentRoute.params.branch_code
    const getChassisNumber = router.currentRoute.params.chassis_number
    const branchFromEdit = ref(null) // เก็บค่าพอร์ตกรณีแก้ไข

    // ประกาศตัวแปร Active / Inactive
    const isPopupBranchActive = ref(false)
    const isPopupCarBrandActive = ref(false)
    const isPopupCarModelActive = ref(false)
    const isPopupCarColorActive = ref(false)
    const isPopupCarEnergyActive = ref(false)
    const isPopupCarGroupActive = ref(false)
    const isPopupCarTypeActive = ref(false)
    const isPopupCooperateActive = ref(false)
    const isPopupCarDealerActive = ref(false)
    const isPopupCarLocationActive = ref(false)
    const isPopupProvinceActive = ref(false)
    const isPopupCarRegisterTypeActive = ref(false)
    const isPopupGPSCodeActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySaveButton = ref(false)
    const overlaySubmitButton = ref(false)

    const resolveFormType = computed(() => {
      let setFormType = ''
      if (router.currentRoute.name === 'car-master-maintainance-form-add') {
        setFormType = 'add'
      } else if (
        router.currentRoute.name === 'car-master-maintainance-form-edit'
      ) {
        setFormType = 'edit'
      } else {
        setFormType = 'remove'
      }

      return setFormType
    })

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* ฟังก์ชั่น Work Scope */
    const fncFetchWorkScope = () => {
      const payload = {
        action: 'VIEW',
        events: 'SIGNIN',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_USER_WORK_SCOPE`, payload)
        .then(response => {
          /* eslint-disable no-console */
          username.value = response.data.responseData[0].username
          cpncod.value = response.data.responseData[0].cpncod

          if (response.data.responseData[0].cpnbrncod) {
            branchCode.value = response.data.responseData[0].cpnbrncod
            branchName.value = response.data.responseData[0].tabdsctha
            isDefaultBranchFromLogin.value = true
          }
        })
        .catch(error => {
          /* eslint-disable no-console */
          console.log(error)
        })
    }

    if (getBranchCode && getChassisNumber) {
      const payload = {
        action: 'EDIT',
        events: 'MAINTENANCE',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        cpncod_in: '0001',
        cpnbrncod_in: getBranchCode,
        chassis_number_in: getChassisNumber,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_MASTER`, payload)
        .then(response => {
          inputReferenceNum.value = response.data.responseData[0].reference_num
          branchCode.value = response.data.responseData[0].cpnbrncod
          InputchassisNum.value = response.data.responseData[0].chassis_number
          inputRegNum1.value = response.data.responseData[0].regnum1
          inputRegNum2.value = response.data.responseData[0].regnum2
          inputEnginNumber.value = response.data.responseData[0].engin_number
          carBrand.value = response.data.responseData[0].car_brand
          carModel.value = response.data.responseData[0].car_model
          carColor.value = response.data.responseData[0].car_color
          carEnergyCode.value = response.data.responseData[0].car_energy
          carGroupCode.value = response.data.responseData[0].car_group
          carTypeCode.value = response.data.responseData[0].car_type
          inputCarDisplacement.value = response.data.responseData[0].car_displacement
          cooperateCode.value = response.data.responseData[0].cooperate_code
          carDealerCode.value = response.data.responseData[0].car_dealer
          GPSCode.value = response.data.responseData[0].gps_code
          inputGPSSerial.value = response.data.responseData[0].gps_serial
          inputGPSSimCode.value = response.data.responseData[0].gps_sim_code
          GPSDateStart.value = response.data.responseData[0].gps_date_start
          inputGPSFee.value = response.data.responseData[0].gps_fee
          carLocation.value = response.data.responseData[0].car_location
          province.value = response.data.responseData[0].adrprovince
          carRegisterTypeCode.value = response.data.responseData[0].carregtyp
          registerDate.value = response.data.responseData[0].regfstdte

          branchName.value = response.data.responseData[0].cpnbrncod_desc
          // labelCarBrand.value = response.data.responseData[0].car_brand_desc;
          // labelCarModel.value = response.data.responseData[0].car_model_desc;
          // labelCarColor.value = response.data.responseData[0].car_color_desc;
          carEnergyName.value = response.data.responseData[0].car_energy_desc
          carGroupName.value = response.data.responseData[0].car_group
          carTypeName.value = response.data.responseData[0].car_type_desc
          cooperateName.value = response.data.responseData[0].cooperate_code_desc
          carDealerName.value = response.data.responseData[0].car_dealer_desc
          // labelGPSCode.value = response.data.responseData[0].gps_code_desc;
          // labelCarLocation.value = response.data.responseData[0].car_location;
          // labelADRProvince.value = response.data.responseData[0].adrprovince;
          carRegisterTypeName.value = response.data.responseData[0].carregtyp_desc

          // ตัวแปรสำหรับหน้าแก้ไข ไว้เช็คค่าซ้ำ
          branchFromEdit.value = response.data.responseData[0].cpnbrncod
          chassisNumFromEdit.value = response.data.responseData[0].chassis_number
        })
    }

    /* ฟังก์ชั่นเปิด Popup พอร์ต */
    const fncOpenPopupBranch = search => {
      if (search) tempSearchBranch.value = search
      else tempSearchBranch.value = null

      isPopupBranchActive.value = true
    }

    /* ฟังก์ชั่นค้นหาพอร์ต */
    const fncSearchBranch = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CPNBRNCOD',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              branchCode.value = getSearchResult[0].tabkeytwo
              branchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupBranch(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelBranch = () => {
      branchCode.value = null
      branchName.value = null
      inputSearchBranch.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncSelectBranch = data => {
      branchCode.value = data.tabkeytwo
      branchName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup ยี่ห้อรถ */
    const fncOpenPopupCarBrand = search => {
      if (search) tempSearchCarBrand.value = search
      else tempSearchCarBrand.value = null

      isPopupCarBrandActive.value = true
    }

    /* ฟังก์ชั่นค้นหายี่ห้อรถ */
    const fncSearchCarBrand = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_BRAND',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carBrand.value = getSearchResult[0].tabkeytwo
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarBrand(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกยี่ห้อรถ */
    const fncCancelCarBrand = () => {
      carBrand.value = null
      inputSearchCarBrand.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกยี่ห้อรถจาก Popup */
    const fncSelectCarBrand = data => {
      carBrand.value = data.tabkeytwo
    }

    /* ฟังก์ชั่นเปิด Popup รุ่นรถ */
    const fncOpenPopupCarModel = search => {
      if (search) tempSearchCarModel.value = search
      else tempSearchCarModel.value = null

      isPopupCarModelActive.value = true
    }

    /* ฟังก์ชั่นค้นหารุ่นรถ */
    const fncSearchCarModel = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_MODEL',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carModel.value = getSearchResult[0].tabkeytwo
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarModel(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกรุ่นรถ */
    const fncCancelCarModel = () => {
      carModel.value = null
      inputSearchCarModel.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกรุ่นรถจาก Popup */
    const fncSelectCarModel = data => {
      carModel.value = data.tabkeytwo
    }

    /* ฟังก์ชั่นเปิด Popup สีรถ */
    const fncOpenPopupCarColor = search => {
      if (search) tempSearchCarColor.value = search
      else tempSearchCarColor.value = null

      isPopupCarColorActive.value = true
    }

    /* ฟังก์ชั่นค้นหาสีรถ */
    const fncSearchCarColor = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_COLOR',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carColor.value = getSearchResult[0].tabkeytwo
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarColor(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกสีรถ */
    const fncCancelCarColor = () => {
      carColor.value = null
      inputSearchCarColor.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกสีรถจาก Popup */
    const fncSelectCarColor = data => {
      carColor.value = data.tabkeytwo
    }

    /* ฟังก์ชั่นเปิด Popup ประเภทเชื้อเพลิง/พลังงาน */
    const fncOpenPopupCarEnergy = search => {
      if (search) tempSearchCarEnergy.value = search
      else tempSearchCarEnergy.value = null

      isPopupCarEnergyActive.value = true
    }

    /* ฟังก์ชั่นค้นหาประเภทเชื้อเพลิง/พลังงาน */
    const fncSearchCarEnergy = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_ENERGY',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carEnergyCode.value = getSearchResult[0].tabkeytwo
              carEnergyName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarEnergy(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกประเภทเชื้อเพลิง/พลังงาน */
    const fncCancelCarEnergy = () => {
      carEnergyCode.value = null
      carEnergyName.value = null
      inputSearchCarEnergy.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกประเภทเชื้อเพลิง/พลังงานจาก Popup */
    const fncSelectCarEnergy = data => {
      carEnergyCode.value = data.tabkeytwo
      carEnergyName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup กลุ่มรถยนต์ */
    const fncOpenPopupCarGroup = search => {
      if (search) tempSearchCarGroup.value = search
      else tempSearchCarGroup.value = null

      isPopupCarGroupActive.value = true
    }

    /* ฟังก์ชั่นค้นหากลุ่มรถยนต์ */
    const fncSearchCarGroup = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_GROUP',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carGroupCode.value = getSearchResult[0].tabkeytwo
              carGroupName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarGroup(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกกลุ่มรถยนต์ */
    const fncCancelCarGroup = () => {
      carGroupCode.value = null
      carGroupName.value = null
      inputSearchCarGroup.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกกลุ่มรถยนต์จาก Popup */
    const fncSelectCarGroup = data => {
      carGroupCode.value = data.tabkeytwo
      carGroupName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup ประเภทรถยนต์ */
    const fncOpenPopupCarType = search => {
      if (search) tempSearchCarType.value = search
      else tempSearchCarType.value = null

      isPopupCarTypeActive.value = true
    }

    /* ฟังก์ชั่นค้นหาประเภทรถยนต์ */
    const fncSearchCarType = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_TYPE',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carTypeCode.value = getSearchResult[0].tabkeytwo
              carTypeName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarType(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกประเภทรถยนต์ */
    const fncCancelCarType = () => {
      carTypeCode.value = null
      carTypeName.value = null
      inputSearchCarType.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกประเภทรถยนต์จาก Popup */
    const fncSelectCarType = data => {
      carTypeCode.value = data.tabkeytwo
      carTypeName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup สหกรณ์ */
    const fncOpenPopupCooperate = search => {
      if (search) tempSearchCooperate.value = search
      else tempSearchCooperate.value = null

      isPopupCooperateActive.value = true
    }

    /* ฟังก์ชั่นค้นหาสหกรณ์ */
    const fncSearchCooperate = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'COOPERATE_CODE',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              cooperateCode.value = getSearchResult[0].tabkeytwo
              cooperateName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCooperate(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกสหกรณ์ */
    const fncCancelCooperate = () => {
      cooperateCode.value = null
      cooperateName.value = null
      inputSearchCooperate.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกสหกรณ์จาก Popup */
    const fncSelectCooperate = data => {
      cooperateCode.value = data.tabkeytwo
      cooperateName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup ตัวแทนจำหน่าย */
    const fncOpenPopupCarDealer = search => {
      if (search) tempSearchCarDealer.value = search
      else tempSearchCarDealer.value = null

      isPopupCarDealerActive.value = true
    }

    /* ฟังก์ชั่นค้นหาตัวแทนจำหน่าย */
    const fncSearchCarDealer = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_DEALER',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carDealerCode.value = getSearchResult[0].tabkeytwo
              carDealerName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarDealer(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกตัวแทนจำหน่าย */
    const fncCancelCarDealer = () => {
      carDealerCode.value = null
      carDealerName.value = null
      inputSearchCarDealer.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกตัวแทนจำหน่ายจาก Popup */
    const fncSelectCarDealer = data => {
      carDealerCode.value = data.tabkeytwo
      carDealerName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup สถานที่เก็บรถ */
    const fncOpenPopupCarLocation = search => {
      if (search) tempSearchCarLocation.value = search
      else tempSearchCarLocation.value = null

      isPopupCarLocationActive.value = true
    }

    /* ฟังก์ชั่นค้นหาสถานที่เก็บรถ */
    const fncSearchCarLocation = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_LOCATION',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carLocation.value = getSearchResult[0].tabkeytwo
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarLocation(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกสถานที่เก็บรถ */
    const fncCancelCarLocation = () => {
      carLocation.value = null
      inputSearchCarLocation.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกสถานที่เก็บรถจาก Popup */
    const fncSelectCarLocation = data => {
      carLocation.value = data.tabkeytwo
    }

    /* ฟังก์ชั่นเปิด Popup ทะเบียนจังหวัด */
    const fncOpenPopupProvince = search => {
      if (search) tempSearchProvince.value = search
      else tempSearchProvince.value = null

      isPopupProvinceActive.value = true
    }

    /* ฟังก์ชั่นค้นหาทะเบียนจังหวัด */
    const fncSearchProvince = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'ADRPROVINCE',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              province.value = getSearchResult[0].tabkeytwo
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupProvince(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกทะเบียนจังหวัด */
    const fncCancelProvince = () => {
      province.value = null
      inputSearchProvince.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกทะเบียนจังหวัดจาก Popup */
    const fncSelectProvince = data => {
      province.value = data.tabkeytwo
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่จดทะเบียน */
    const fncShowDatepickerRegisterDate = () => {
      refModalDatePickerRegisterDate.value.show()
    }

    /* ฟังก์ชั่นเปิด Popup ประเภทการจดทะเบียน */
    const fncOpenPopupCarRegisterType = search => {
      if (search) tempSearchCarRegisterType.value = search
      else tempSearchCarRegisterType.value = null

      isPopupCarRegisterTypeActive.value = true
    }

    /* ฟังก์ชั่นค้นหาประเภทการจดทะเบียน */
    const fncSearchCarRegisterType = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CARREGTYP',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carRegisterTypeCode.value = getSearchResult[0].tabkeytwo
              carRegisterTypeName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarRegisterType(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกประเภทการจดทะเบียน */
    const fncCancelCarRegisterType = () => {
      carRegisterTypeCode.value = null
      carRegisterTypeName.value = null
      inputSearchCarRegisterType.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกประเภทการจดทะเบียนจาก Popup */
    const fncSelectCarRegisterType = data => {
      carRegisterTypeCode.value = data.tabkeytwo
      carRegisterTypeName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นเปิด Popup ยี่ห้อ/รุ่น */
    const fncOpenPopupGPSCode = search => {
      if (search) tempSearchGPSCode.value = search
      else tempSearchGPSCode.value = null

      isPopupGPSCodeActive.value = true
    }

    /* ฟังก์ชั่นค้นหายี่ห้อ/รุ่น */
    const fncSearchGPSCode = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_BRAND',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              GPSCode.value = getSearchResult[0].tabkeytwo
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupGPSCode(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกยี่ห้อ/รุ่น */
    const fncCancelGPSCode = () => {
      GPSCode.value = null
      inputSearchGPSCode.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกยี่ห้อ/รุ่นจาก Popup */
    const fncSelectGPSCode = data => {
      GPSCode.value = data.tabkeytwo
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่ติดตั้ง GPS */
    const fncShowDatepickerGPSDateStart = () => {
      refModalDatePickerGPSDateStart.value.show()
    }

    const fncCheckDuplicateChassisNumber = () => {
      const payload = {
        action: 'ADD',
        events: 'MAINTENANCE',
        function: 'VERIFY',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        cpncod_in: '0001',
        cpnbrncod_in: branchCode.value,
        chassis_number_in: InputchassisNum.value,
      }

      return new Promise((resolve, reject) => {
        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_MASTER`, payload)
          .then(response => {
            if (response.data.responseData[0].success !== 'SUCCESS' && chassisNumFromEdit.value !== InputchassisNum.value) {
              isDuplicateData.value = true
            } else {
              isDuplicateData.value = false
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    }

    /* ฟังก์ชั่นเมื่อกดปุ่ม Submit */
    const fncSubmit = async isSave => {
      overlaySubmitButton.value = true
      setInterval(() => { overlaySubmitButton.value = false }, 5000)

      const payload = {
        action: 'ADD',
        events: 'MAINTENANCE',
        function: 'SUBMIT',
        CPNCOD_IN: '0001',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        CPNBRNCOD_IN: branchCode.value,
        CHASSIS_NUMBER_IN: InputchassisNum.value,
        REGNUM1_IN: inputRegNum1.value,
        REGNUM2_IN: inputRegNum2.value,
        CAR_STATUS_IN: null,
        ENGIN_NUMBER_IN: inputEnginNumber.value,
        CAR_BRAND_IN: carBrand.value,
        CAR_MODEL_IN: carModel.value,
        CAR_COLOR_IN: carColor.value,
        CAR_ENERGY_IN: carEnergyCode.value,
        CAR_GROUP_IN: carGroupCode.value,
        CAR_TYPE_IN: carTypeName.value,
        CAR_DISPLACEMENT_IN: inputCarDisplacement.value,
        COOPERATE_CODE_IN: cooperateCode.value,
        OWNER_IN: null,
        CAR_DEALER_IN: carDealerCode.value,
        GPS_CODE_IN: GPSCode.value,
        GPS_SERIAL_IN: inputGPSSerial.value,
        GPS_SIM_CODE_IN: inputGPSSimCode.value,
        GPS_DATE_START_IN: GPSDateStart.value,
        GPS_FEE_IN: inputGPSFee.value,
        CAR_LOCATION_IN: carLocation.value,
        REMARK_IN: null,
        ADRPROVINCE_IN: province.value,
        CARREGTYP_IN: carRegisterTypeCode.value,
        REGFSTDTE_IN: registerDate.value,
      }

      // eslint-disable-next-line camelcase
      if (isSave) payload.function = 'SAVE'

      if (resolveFormType.value === 'add') {
        /* กรณีเพิ่มข้อมูลคำาสั่งนี้จะทำงาน */
        await fncCheckDuplicateChassisNumber()
        if (!isDuplicateData.value) {
          store
            .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_MASTER`, payload)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'แจ้งเตือน',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูลสำเร็จ',
                },
              })

              router.push('/car_master_maintainance') // คำสั่ง Redirect
            })
        }
      } else if (resolveFormType.value === 'edit') {
        /* กรณีแก้ไขข้อมูลคำสั่งนี้จะทำงาน */
        payload.action = 'EDIT'

        await fncCheckDuplicateChassisNumber()

        if (!isDuplicateData.value) {
          store
            .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_MASTER`, payload)
            .then(() => {
              router.push('/car_master_maintainance') // คำสั่ง Redirect
              toast({
                component: ToastificationContent,
                props: {
                  title: 'แจ้งเตือน',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'แก้ไขข้อมูลสำเร็จ',
                },
              })
            })
        }
      } else {
        payload.action = 'CANCEL'

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_MASTER`, payload)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'แจ้งเตือน',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'ลบข้อมูลสำเร็จ',
              },
            })
            router.push('/car_master_maintainance') // คำสั่ง Redirect
          })
      }
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    /* Life Cycle Onmunted */
    onMounted(() => {
      fncFetchWorkScope()
    })

    return {
      resolveFormType,

      // ประกาศตัวแปร Ref
      refModalDatePickerRegisterDate,
      refModalDatePickerGPSDateStart,

      // ประกาศตัวแปร Textbox
      inputSearchBranch,
      InputchassisNum,
      inputReferenceNum,
      inputEnginNumber,
      inputCarDisplacement,
      inputSearchCarBrand,
      inputSearchCarModel,
      inputSearchCarColor,
      inputSearchCarEnergy,
      inputSearchCarGroup,
      inputSearchCarType,
      inputSearchCooperate,
      inputSearchCarDealer,
      inputSearchCarLocation,
      inputRegNum1,
      inputRegNum2,
      inputSearchProvince,
      inputSearchCarRegisterType,
      inputSearchGPSCode,
      inputGPSSerial,
      inputGPSSimCode,
      inputGPSFee,

      // ประกาศตัวแปรสำหรับเก็บค่า
      username,
      cpncod,
      branchCode,
      branchName,
      carBrand,
      carModel,
      carColor,
      carEnergyCode,
      carEnergyName,
      carGroupCode,
      carGroupName,
      carTypeCode,
      carTypeName,
      cooperateCode,
      cooperateName,
      carDealerCode,
      carDealerName,
      carLocation,
      province,
      carRegisterTypeCode,
      carRegisterTypeName,
      GPSCode,
      tempSearchBranch,
      tempSearchCarBrand,
      tempSearchCarModel,
      tempSearchCarColor,
      tempSearchCarEnergy,
      tempSearchCarGroup,
      tempSearchCarType,
      tempSearchCooperate,
      tempSearchCarDealer,
      tempSearchCarLocation,
      tempSearchProvince,
      tempSearchCarRegisterType,
      tempSearchGPSCode,
      isDefaultBranchFromLogin,
      registerDate,
      GPSDateStart,
      isDuplicateData,
      chassisNumFromEdit,
      branchFromEdit,

      // ประกาศตัวแปร Active / Inactive
      isPopupBranchActive,
      isPopupCarBrandActive,
      isPopupCarModelActive,
      isPopupCarColorActive,
      isPopupCarEnergyActive,
      isPopupCarGroupActive,
      isPopupCarTypeActive,
      isPopupCooperateActive,
      isPopupCarDealerActive,
      isPopupCarLocationActive,
      isPopupProvinceActive,
      isPopupCarRegisterTypeActive,
      isPopupGPSCodeActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySaveButton,
      overlaySubmitButton,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupBranch,
      fncSearchBranch,
      fncCancelBranch,
      fncSelectBranch,
      fncOpenPopupCarBrand,
      fncSearchCarBrand,
      fncCancelCarBrand,
      fncSelectCarBrand,
      fncOpenPopupCarModel,
      fncSearchCarModel,
      fncCancelCarModel,
      fncSelectCarModel,
      fncOpenPopupCarColor,
      fncSearchCarColor,
      fncCancelCarColor,
      fncSelectCarColor,
      fncOpenPopupCarEnergy,
      fncSearchCarEnergy,
      fncCancelCarEnergy,
      fncSelectCarEnergy,
      fncOpenPopupCarGroup,
      fncSearchCarGroup,
      fncCancelCarGroup,
      fncSelectCarGroup,
      fncOpenPopupCarType,
      fncSearchCarType,
      fncCancelCarType,
      fncSelectCarType,
      fncOpenPopupCooperate,
      fncSearchCooperate,
      fncCancelCooperate,
      fncSelectCooperate,
      fncOpenPopupCarDealer,
      fncSearchCarDealer,
      fncCancelCarDealer,
      fncSelectCarDealer,
      fncOpenPopupCarLocation,
      fncSearchCarLocation,
      fncCancelCarLocation,
      fncSelectCarLocation,
      fncOpenPopupProvince,
      fncSearchProvince,
      fncCancelProvince,
      fncSelectProvince,
      fncShowDatepickerRegisterDate,
      fncOpenPopupCarRegisterType,
      fncSearchCarRegisterType,
      fncCancelCarRegisterType,
      fncSelectCarRegisterType,
      fncOpenPopupGPSCode,
      fncSearchGPSCode,
      fncCancelGPSCode,
      fncSelectGPSCode,
      fncShowDatepickerGPSDateStart,
      fncSubmit,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,
    }
  },
}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

/* for local only */
/* .delete-margin {
  margin-bottom: -60px !important;
} */
</style>
